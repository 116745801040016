import React, {useEffect, useMemo, useState} from 'react';
import {Button, Checkbox, Col, Divider, Form, Input, notification, Row, Select, Space, Spin} from 'antd';
import {FieldConfig, formConfig, Section} from '../models/productFormData';
import {InfoCircleOutlined, MinusCircleOutlined, PlusOutlined} from '@ant-design/icons';
import './ProductForm.css';
import {useParams} from 'react-router-dom';
import SelectComponent from '../components/Select';
import {selectorItemsApi} from '../apiCalls/selectorItemsApi';
import {productsApi} from '../apiCalls/productsApi';
import CheckboxList from '../components/CheckboxList';
import SectionTitle from "../components/SectionTitle";
import {CommaInput} from "../components/CommaInput";

const UNITS_INPUTS_WITH = '300px';

const FATS_CHILD_FIELDS = [
  'fatsSaturates',
  'fatsMonoUnsat',
  'fatsPolyUnsat',
  'fatsTransFats',
];

const CARBO_CHILD_FIELDS = [
  'carbohydrateSugars',
  'carbohydratePolyols',
  'carbohydrateStarch',
];

const EditIngredientForm: React.FC = () => {
  const [sections, setSections] = useState<Section[]>(formConfig.form.sections);
  const [isLoading, setIsLoading] = useState(true);
  const [form] = Form.useForm();
  const [quantitySelectorValues, setQuantitySelectorValues] = useState<{ [key: string]: string | undefined }>({});
  const [unitClaimValue, setUnitClaimValue] = useState(' ');
  const [productData, setProductData] = useState<any>(null);
  const [initialValues, setInitialValues] = useState<any>({});
  const [exceedsFats, setExceedsFats] = useState(false);
  const [exceedsCarbo, setExceedsCarbo] = useState(false);
  const { id } = useParams<{ id: string }>();
  const watchFats          = Form.useWatch('fats', form);
  const watchFatsSaturates = Form.useWatch('fatsSaturates', form);
  const watchFatsMono      = Form.useWatch('fatsMonoUnsat', form);
  const watchFatsPoly      = Form.useWatch('fatsPolyUnsat', form);
  const watchFatsTrans     = Form.useWatch('fatsTransFats', form);
  const watchCarbo          = Form.useWatch('carbohydrate', form);
  const watchCarboSugars    = Form.useWatch('carbohydrateSugars', form);
  const watchCarboPolyols   = Form.useWatch('carbohydratePolyols', form);
  const watchCarboStarch    = Form.useWatch('carbohydrateStarch', form);

  useEffect(() => {
    const fats          = parseFloat(watchFats          ?? 0);
    const saturates     = parseFloat(watchFatsSaturates ?? 0);
    const monoUnsat     = parseFloat(watchFatsMono      ?? 0);
    const polyUnsat     = parseFloat(watchFatsPoly      ?? 0);
    const transFats     = parseFloat(watchFatsTrans     ?? 0);
    const sum = saturates + monoUnsat + polyUnsat + transFats;

    if (sum > fats) {
      setExceedsFats(true);
    } else {
      setExceedsFats(false);
    }
  }, [
    watchFats,
    watchFatsSaturates,
    watchFatsMono,
    watchFatsPoly,
    watchFatsTrans
  ]);

  useEffect(() => {
    const carb = parseFloat(watchCarbo ?? 0);
    const sug  = parseFloat(watchCarboSugars ?? 0);
    const pol  = parseFloat(watchCarboPolyols ?? 0);
    const sta  = parseFloat(watchCarboStarch ?? 0);

    const sumCarbo = sug + pol + sta;
    setExceedsCarbo(sumCarbo > carb);
  }, [
    watchCarbo,
    watchCarboSugars,
    watchCarboPolyols,
    watchCarboStarch
  ]);

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const data = await productsApi.getProductById(id ?? '');
        setProductData(data);
      } catch (error) {
        console.error('Error fetching product data:', error);
        setIsLoading(false);
      }
    };

    fetchProductData();
  }, [id]);

  useEffect(() => {
    const completeFields = async () => {
      try {
        const updatedSections = await Promise.all(
            sections.map(async (section) => {
              if (section.fieldsEndpoint) {
                try {
                  const fields = await selectorItemsApi.getItemList(section.fieldsEndpoint);
                  const inputs = fields.map((field) => ({
                    label: field.name,
                    name: field.name,
                    id: field.id,
                    obligatory: false,
                    disabled: false,
                    type: 'checkbox',
                  })) as FieldConfig[];

                  return { ...section, fields: section.fields.concat(inputs) };
                } catch (error) {
                  console.error(`Error fetching fields for section ${section.title}:`, error);
                  return section;
                }
              }

              return section;
            }),
        );

        setSections(updatedSections);
      } catch (error) {
        console.error('Error completing fields:', error);
      }
    };

    completeFields();
  }, []);

  useEffect(() => {
    if (productData) {
      const initialValues = { ...productData };

      if (productData.productAccordingTo?.length > 0) {
        productData.productAccordingTo.forEach((item: { id: string; name: string }) => {
          initialValues[item.name] = true;
        });
      }

      if (productData.productAllergens?.length > 0) {
        productData.productAllergens.forEach((item: { id: string; name: string }) => {
          initialValues[item.name] = true;
        });
      }

      if (productData?.activeProducts?.length > 0) {
        initialValues.actives = productData?.activeProducts.map((a: any) => ({
          id: a.id,
          activeProducts: a.active.id,
          activeValue: a.activeValue,
          declarable: a.declarable
        }));
      }

      const quantitySelector: { [key: string]: string } = {};

      sections.forEach((section) => {
        section.fields.forEach((field) => {
          if (field.type === 'textInputNumberQuantitySelector' && field.options) {
            quantitySelector[field.name + 'Unit'] = productData[field.name + 'Unit'];
          }
        });
      });

      setQuantitySelectorValues(quantitySelector);

      if (productData.unitClaim) {
        setUnitClaimValue(productData.unitClaim);
      }

      setInitialValues(initialValues);
      form.setFieldsValue(initialValues);
      setIsLoading(false);
    }
  }, [productData, form, sections]);

  const handleSubmit = async (values: any) => {
    const formattedValues: { [key: string]: any } = values;
    sections.forEach((section) => {
      if (section.fieldsEndpoint) {
        if (section.name === 'productAccordingTo') {
          const accordingMap: Record<string, boolean> = {};

          section.fields.forEach((field) => {
            if (values[field.name]) {
              if (field.id) {
                accordingMap[field.id] = true;
              }
            }
          });

          formattedValues[section.name] = accordingMap;
        } else if (section.name === 'productAllergens') {
          const allergensMap: Record<string, boolean> = {};

          section.fields.forEach((field) => {
            if (values[field.name]) {
              if (field.id) {
                allergensMap[field.id] = true;
              }
            }
          });

          formattedValues[section.name] = allergensMap;
        } else {
          const sectionValues: { [key: string]: any } = {};
          section.fields.forEach((field) => {
            if (!field.id) return;
            sectionValues[field.id] = values[field.name];
            delete formattedValues[field.name];
          });
          if (section.name) {
            formattedValues[section.name] = sectionValues;
          }
        }

        section.fields.forEach((field) => {
          if (!field.id) return;
          delete formattedValues[field.name];
        });
      } else if (section.fields) {
        section.fields.forEach((field) => {
          if (field.type === 'selector' && values[field.name]?.id) {
            formattedValues[field.name] = values[field.name].id;
          }
        });
      }
    });

    if (formattedValues.actives && Array.isArray(formattedValues.actives)) {
      formattedValues.actives = formattedValues.actives.map((active) => {
        if (active.id) {
          return {
            id: active.id,
            activeName: active.activeProducts,
            activeValue: active.activeValue,
            declarable: !!active.declarable,
          };
        }

        return {
          activeName: active.activeProducts,
          activeValue: active.activeValue,
          declarable: !!active.declarable,
        };
      });
    }

    const productId = id; // Usar el id de la URL
    const formattedValuesWithUnits = { ...formattedValues, ...quantitySelectorValues };

    try {
      await productsApi.editProduct(formattedValuesWithUnits, `${productId}`);

      notification.success({
        message: 'Operación exitosa',
        description: 'Tu producto se ha editado con éxito.',
        duration: 3,
      });
    } catch (e) {
      notification.error({
        message: 'Error',
        description: 'Ocurrió un error, vuelva a intentar',
        duration: 3,
      });
    }
  };

  const renderFormItem = (sectionTitle: string, item: FieldConfig, defaultSelectValue?: string, index?: number) => {
    let unit = item.unit ? item.unit : undefined;

    if (item.name === 'vrn') {
      unit = unitClaimValue;
    }

    const inputStyle =
        sectionTitle === 'Nutritional Values' || sectionTitle === 'Microbiology'
            ? { width: UNITS_INPUTS_WITH }
            : {};

    if (item.type === 'selector' && item.label === 'Active name' ) {
      defaultSelectValue = productData?.[item.name]?.[index ?? 0]?.active
    }

    switch (item.type) {
      case 'textInputNumber':
        return (
          <CommaInput
            placeholder={`Enter ${item.label}`}
            addonAfter={unit}
            disabled={item.disabled}
            min={0}
            step={item.step ?? 0.000001}
            style={inputStyle}
          />
        );
      case 'textInputNumberQuantitySelector':
        return (
          <CommaInput
            placeholder={`Enter ${item.label}`}
            addonAfter={
              <Select
                onSelect={(value) => {
                  setQuantitySelectorValues((prevValues) => ({
                    ...prevValues,
                    [item.name + 'Unit']: value,
                  }));
                }}
                // defaultValue={items?.options ? items.options[0] : ''}
                defaultValue={quantitySelectorValues[item.name + 'Unit']}
                style={{width: 90}}
              >
                {item?.options?.map((item, index) => (
                  <Select.Option key={`${item}-${index}`} value={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            }
            disabled={item.disabled}
          />
        );
      case 'textInputString':
        return (
          <Input
            placeholder={`Enter ${item.label}`}
            addonAfter={item.unit ? item.unit : undefined}
            disabled={item.disabled}
            style={{
              fontStyle: item.cursive ? 'italic' : undefined,
              ...inputStyle
            }}
          />
        );
      case 'checkbox':
        return (
            <Checkbox>{item.label}</Checkbox>
        );
      case 'selector':
        return (
          <SelectComponent
            title={item?.label}
            onChange={(value) => {
              if (item.name === 'unitClaim') {
                setUnitClaimValue(value);
              }
            }}
            defaultValue={defaultSelectValue ? defaultSelectValue : productData?.[item.name] ?? ''}
            endpoint={item.endpoint}
            postEndpoint={item.postEndpoint}
            postExtraValues={item.postExtraValues}
            canDoPostRequest={item.canDoPostRequest}
          ></SelectComponent>
        );
      case 'checkboxListOneOption':
        return <CheckboxList name={item.name} form={form} endpoint={item.endpoint!}></CheckboxList>;
    }
  };

  const getCustomStyles = (fieldName: string) => {
    if (FATS_CHILD_FIELDS.includes(fieldName) || CARBO_CHILD_FIELDS.includes(fieldName)) {
      return { marginLeft: 32 };
    }
    return {};
  };

  const renderForm = (sectionTitle: string, item: FieldConfig, itemsSizeInRow = 8) => {
    return (
      <Col key={`${sectionTitle}-${item.label}`} xs={24} sm={12} md={itemsSizeInRow} lg={itemsSizeInRow}>
        <Form.Item
          className={item.type === 'checkbox' ? 'custom-checkbox-item' : 'custom-form-item'}
          style={{
            marginBottom: item.type === 'checkbox' ? '8px' : '12px',
            ...getCustomStyles(item.name)
          }}
          label={item.type === 'checkbox' ? ' ' : item.label}
          name={item.name}
          valuePropName={item.type === 'checkbox' ? 'checked' : undefined}
          tooltip={
            item.tooltip
              ? {
                  title: item.tooltip,
                  icon: <InfoCircleOutlined style={{marginLeft: 10, color: 'black'}} />,
                }
              : undefined
          }
        >
          {renderFormItem(sectionTitle, item)}
        </Form.Item>
        {item.name === 'fats' && exceedsFats && (
            <div style={{ color: 'red' }}>
              The sum of saturated, monounsaturated, polyunsaturated and trans fats exceeds the value of fats.
            </div>
        )}
        {item.name === 'carbohydrate' && exceedsCarbo && (
            <div style={{ color: 'red' }}>
              The sum of sugars, polyols and starch exceeds Carbohydrate.
            </div>
        )}
      </Col>
    );
  };

  const renderFormCanAddRow = (
    section: Section,
    dynamicFields: any[],
    add: () => void,
    remove: (index: number) => void,
  ) => {
    const size = section.itemsSizeInRow ? section.itemsSizeInRow : 11;

    return (
      <>
        {dynamicFields.map((dynamicField, index) => (
          <div style={{display: section.itemsSizeInRow ? 'flex' : 'flex'}} key={`${dynamicField.key}-${index}`}>
            <Row style={{width: '100%'}} gutter={16} key={dynamicField.key}>
              {section.fields.map((field) => {
                const fieldName = field.type === 'checkbox' ? field.name + index : field.name;

                let defaultSelectValue = '';

                const purchases = productData?.purchases;

                if (purchases && section.title === 'Purchases' && field.type === 'selector') {
                  if (purchases.length > index) {
                    defaultSelectValue = purchases[index][field?.name]?.name;
                  }
                }

                const actives = productData?.actives;

                if (actives && section.name === 'actives' && field.type === 'selector') {
                  if (actives.length > index) {
                    defaultSelectValue = actives[index][field?.name];
                  }
                }
                return (
                    <Col xs={24} sm={12} md={size} lg={size} key={`${field.label}-${index}`}>
                      {field.type === 'checkbox' ? (
                          <Form.Item
                              style={{height: 50}}
                              label={field.label + (section.name === 'actives' ? ' #' + (index + 1) : '')}
                              name={[dynamicField.name, 'declarable']}
                              fieldKey={[dynamicField.fieldKey, field.label.toLowerCase()]}
                              valuePropName="checked"
                          >
                            <Checkbox />
                          </Form.Item>
                      ) : (
                          <Form.Item
                              style={{height: 50}}
                              label={field.label + (section.name === 'actives' ? ' #' + (index + 1) : '')}
                              name={[dynamicField.name, field.name]}
                              fieldKey={[dynamicField.fieldKey, field.label.toLowerCase()]}
                          >
                            {renderFormItem(field.label, field, defaultSelectValue, index)}
                          </Form.Item>
                      )}
                    </Col>
                );
              })}
              <Divider
                orientationMargin={0}
                style={{
                  marginBottom: 10,
                  marginTop: 10,
                }}
              ></Divider>
            </Row>

            <MinusCircleOutlined style={{marginLeft: 10}} onClick={() => remove(index)} />
          </div>
        ))}

        <Form.Item style={{height: 50, marginTop: 10}}>
          <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
            {section.name === 'actives' ? 'Add Active' : 'Add Row'}
          </Button>
        </Form.Item>
      </>
    );
  };

  if (isLoading) {
    return (
      <Spin style={{justifyContent: 'center', flex: 1, marginTop: 150}} tip="Loading" size="large">
        <div className="content" />
      </Spin>
    );
  }

  return (
    <div className={'container'}>
      <h2 style={{marginBottom: 50, marginLeft: -5}}>Edit Ingredient</h2>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        initialValues={initialValues}
        // initialValues={{VEGETARIAN: true, id: 123}}
      >
        {sections.map((section, sectionIndex) => (
          <div key={`${section.title.trim()}-${sectionIndex}`}>
            {section.title && (
                <SectionTitle title={section.title} isSubtitle={section.isSubtitle} />
            )}

            {section.canAddRows ? (
              <Form.List name={section.name ?? section.title}>
                {(fields, {add, remove}) => renderFormCanAddRow(section, fields, add, remove)}
              </Form.List>
            ) : (
              <Row gutter={16}>{section.fields.map((field) => renderForm(section.title, field, section.itemsSizeInRow))}</Row>
            )}
          </div>
        ))}
        <Space style={{marginTop: 16, marginBottom: 30}}>
          <Button type="primary" htmlType="submit">
            Save Ingredient
          </Button>
        </Space>
      </Form>
    </div>
  );
};

export default EditIngredientForm;
