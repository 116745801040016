import React, { useEffect, useState } from 'react';
import { Form, Input, Button, notification, Row, Col, Select } from 'antd';
import { useParams } from 'react-router-dom';
import { activesApi } from '../apiCalls/activesApi';

const { Option } = Select;

const ActiveFormEdit: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(true);
  const [vrnUnit, setVrnUnit] = useState<string>('mg/day');
  const [vrnChildrenUnit, setVrnChildrenUnit] = useState('mg/day');
  const [ulUnit, setUlUnit] = useState<string>('mg/day');
  const [rdiUnit, setRdiUnit] = useState('mg/day');
  const [solidsUnit, setSolidsUnit] = useState('g/Kg');
  const [liquidUnit, setLiquidUnit] = useState('g/L');
  const [syrupUnit, setSyrupUnit] = useState('g/Kg');

  useEffect(() => {
    const fetchActive = async () => {
      setLoading(true);
      const active: any = await activesApi.getActiveById(id!);
      if (active) {
        console.log('hello active')
        console.log(active)
        form.setFieldsValue({
          name: active.name,
          vrn: active.vrn,
          vrnChildren: active.vrnChildren,
          iuClaim: active.iuClaim,
          ul: active.ul,
          solidsLimit: active.limitSolids,
          liquidLimit: active.limitLiquids,
          syrupLimit: active.limitSyrups,
          rdi: active.recDairyIntake
        });
        setVrnUnit(active.vrnUnit || 'mg/day');
        setVrnChildrenUnit(active.vrnChildrenUnit || 'mg/day');
        setUlUnit(active.ulUnit || 'mg/day');
        setRdiUnit(active.recDairyIntakeUnit || 'mg/day');
        setSolidsUnit(active.limitSolidsUnit || 'mg/kg');
        setLiquidUnit(active.limitLiquidsUnit || 'mg/l');
        setSyrupUnit(active.limitSyrupsUnit || 'mg/kg');
      } else {
        notification.error({
          message: 'Error',
          description: 'Active not found.',
          duration: 3,
        });
      }
      setLoading(false);
    };

    fetchActive();
  }, [id, form]);

  const onFinish = async (values: any) => {
    const activeData = {
      ...values,
      vrn: values.vrn ? Number(values.vrn) : null,
      vrnUnit,
      vrnChildren: values.vrnChildren ? Number(values.vrnChildren) : null,
      vrnChildrenUnit,
      ul: values.ul ? Number(values.ul) : null,
      ulUnit,
      limitSolids: values.solidsLimit ? Number(values.solidsLimit) : null,
      limitSolidsUnit: solidsUnit,
      limitLiquids: values.liquidLimit ? Number(values.liquidLimit) : null,
      limitLiquidsUnit: liquidUnit,
      limitSyrups: values.syrupLimit ? Number(values.syrupLimit) : null,
      limitSyrupsUnit: syrupUnit,
      recDairyIntake: values.rdi ? Number(values.rdi) : null,
      recDairyIntakeUnit: rdiUnit,
    };

    console.log('data sent')
    console.log(activeData)

    const result = await activesApi.updateActive(id!, activeData);
    if (result) {
      notification.success({
        message: 'Active Updated',
        description: 'The active has been successfully updated.',
        duration: 3,
      });
    } else {
      notification.error({
        message: 'Error updating',
        description: 'There was an error updating the asset.',
        duration: 3,
      });
    }
  };

  return (
      <div style={{ padding: 24 }}>
        <h2>Edit Active</h2>
        <Form layout="vertical" onFinish={onFinish} form={form}>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="Name" name="name">
                <Input placeholder="Enter the name" disabled />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="I.U. Claim" name="iuClaim">
                <Input placeholder="Enter I.U. Claim" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="VRN" name="vrn">
                <Input
                    type="number"
                    placeholder="Enter VRN"
                    addonAfter={
                      <Select
                          placeholder="Unit"
                          style={{ width: 90 }}
                          value={vrnUnit}
                          onChange={(value) => {
                            setVrnUnit(value);
                            setVrnChildrenUnit(value);
                            setUlUnit(value);
                            setRdiUnit(value);
                          }}
                      >
                        <Option value="g/day">g/day</Option>
                        <Option value="mg/day">mg/day</Option>
                        <Option value="ug/day">ug/day</Option>
                      </Select>
                    }
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="VRN Children" name="vrnChildren">
                <Input
                    type="number"
                    placeholder="Enter VRN Children"
                    addonAfter={
                      <Select
                          placeholder="Unit"
                          style={{ width: 90 }}
                          value={vrnChildrenUnit}
                          onChange={(value) => setVrnChildrenUnit(value)}
                      >
                        <Option value="g/day">g/day</Option>
                        <Option value="mg/day">mg/day</Option>
                        <Option value="ug/day">ug/day</Option>
                      </Select>
                    }
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="UL" name="ul">
                <Input
                    type="number"
                    placeholder="Enter UL"
                    addonAfter={
                      <Select
                          placeholder="Unit"
                          style={{ width: 90 }}
                          value={ulUnit}
                          onChange={(value) => setUlUnit(value)}
                      >
                        <Option value="g/day">g/day</Option>
                        <Option value="mg/day">mg/day</Option>
                        <Option value="ug/day">ug/day</Option>
                      </Select>
                    }
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="RDI (Rec. Daily Intake)" name="rdi">
                <Input
                    type="number"
                    placeholder="Enter RDI"
                    addonAfter={
                      <Select
                          value={rdiUnit}
                          onChange={setRdiUnit}
                          style={{ width: 90 }}
                      >
                        <Option value="g/day">g/day</Option>
                        <Option value="mg/day">mg/day</Option>
                        <Option value="ug/day">ug/day</Option>
                      </Select>
                    }
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="Liquid Limits" name="liquidLimit">
                <Input
                    type="number"
                    placeholder="Enter Liquid Limits"
                    addonAfter={
                      <Select
                          value={liquidUnit}
                          onChange={setLiquidUnit}
                          style={{ width: 90 }}
                      >
                        <Option value="g/L">g/L</Option>
                        <Option value="mg/L">mg/L</Option>
                        <Option value="ug/L">ug/L</Option>
                      </Select>
                    }
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Syrup/Gel Limits" name="syrupLimit">
                <Input
                    type="number"
                    placeholder="Enter Syrup Limits"
                    addonAfter={
                      <Select
                          value={syrupUnit}
                          onChange={setSyrupUnit}
                          style={{ width: 90 }}
                      >
                        <Option value="g/Kg">g/Kg</Option>
                        <Option value="mg/Kg">mg/Kg</Option>
                        <Option value="ug/Kg">ug/Kg</Option>
                      </Select>
                    }
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="Solids Limits" name="solidsLimit">
                <Input
                    type="number"
                    placeholder="Enter Solids Limits"
                    addonAfter={
                      <Select
                          value={solidsUnit}
                          onChange={setSolidsUnit}
                          style={{ width: 90 }}
                      >
                        <Option value="g/Kg">g/Kg</Option>
                        <Option value="mg/Kg">mg/Kg</Option>
                        <Option value="ug/Kg">ug/Kg</Option>
                      </Select>
                    }
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              Save Active
            </Button>
          </Form.Item>
        </Form>
      </div>
  );
};

export default ActiveFormEdit;
