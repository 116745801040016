import React, { useEffect, useState } from 'react';
import { Form, Input, Button, notification, Row, Col } from 'antd';
import { useParams } from 'react-router-dom';
import { claimsApi } from '../apiCalls/claimsApi';

const ClaimFormEdit: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchClaim = async () => {
      setLoading(true);
      const claim: any = await claimsApi.getClaimById(id!);
      if (claim) {
        console.log('hello claim')
        console.log(claim)
        form.setFieldsValue({
          name: claim.name,
        });
      } else {
        notification.error({
          message: 'Error',
          description: 'Claim not found.',
          duration: 3,
        });
      }
      setLoading(false);
    };

    fetchClaim();
  }, [id, form]);

  const onFinish = async (values: any) => {
    const claimData = {
      ...values,
    };

    console.log('data sent')
    console.log(claimData)

    const result = await claimsApi.updateClaim(id!, claimData);
    if (result) {
      notification.success({
        message: 'Claim Updated',
        description: 'The claim has been successfully updated.',
        duration: 3,
      });
    } else {
      notification.error({
        message: 'Error updating',
        description: 'There was an error updating the asset.',
        duration: 3,
      });
    }
  };

  return (
      <div style={{ padding: 24 }}>
        <h2>Edit Claim</h2>
        <Form layout="vertical" onFinish={onFinish} form={form}>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="Name" name="name">
                <Input placeholder="Enter the name" />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              Save Claim
            </Button>
          </Form.Item>
        </Form>
      </div>
  );
};

export default ClaimFormEdit;
