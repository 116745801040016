import { apiClient } from './apiClient';

export interface Warning {
  id: string;
  name: string;
}

export const warningsApi = {
  getWarnings: async (): Promise<Warning[]> => {
    try {
      const response = await apiClient.get<Warning[]>('/lists/warnings');
      return response.data;
    } catch (error) {
      console.error('Error fetching warnings:', error);
      return [];
    }
  },
  getWarningById: async (id: string): Promise<Warning | null> => {
    try {
      const response = await apiClient.get<Warning>(`/warnings/${id}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching warning by ID:', error);
      return null;
    }
  },
  createWarning: async (warningData: Partial<Warning>): Promise<Warning | null> => {
    try {
      const response = await apiClient.post<Warning>('/warnings', warningData);
      return response.data;
    } catch (error) {
      console.error('Error creating warning:', error);
      return null;
    }
  },
  updateWarning: async (id: string, warningData: Partial<Warning>): Promise<Warning | null> => {
    try {
      const response = await apiClient.post<Warning>(`/warnings/${id}/update`, warningData);
      return response.data;
    } catch (error) {
      console.error('Error updating warning:', error);
      return null;
    }
  },
  deleteWarning: async (id: string): Promise<boolean> => {
    try {
      await apiClient.delete(`/warnings/${id}/delete`);
      return true;
    } catch (error: any) {
      if (error.response && error.response.status === 403) {
        console.error('Cannot delete warning, it is used in a product/ingredient.');
      } else {
        console.error('Error deleting warning:', error);
      }
      return false;
    }
  },
};
