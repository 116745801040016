import React, { useEffect, useState } from 'react';
import { Table, Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { staticsApi, Static } from '../apiCalls/staticsApi';

const StaticsList: React.FC = () => {
  const navigate = useNavigate();
  const [dataSource, setDataSource] = useState<Static[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    fetchStatics();
  }, []);

  const fetchStatics = async () => {
    setLoading(true);
    const statics = await staticsApi.getStatics();
    setDataSource(statics);
    setLoading(false);
  };

  const columns = [
    {
      title: 'Edit',
      key: 'edit',
      fixed: 'left' as const,
      width: 70,
      render: (_: any, record: Static) => (
          <Button
              onClick={() => {
                navigate(`/edit-static/${record.id}`);
              }}
              icon={<EditOutlined />}
          />
      ),
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      width: 400,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    }
  ];

  return (
      <div style={{ padding: 24 }}>
        <h2>List of Statics</h2>
        <Table
            dataSource={dataSource}
            columns={columns}
            rowKey="id"
            loading={loading}
            scroll={{ x: 'max-content' }}
        />
      </div>
  );
};

export default StaticsList;
