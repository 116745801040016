import React, { useEffect, useState } from 'react';
import { Form, Input, Button, notification, Row, Col } from 'antd';
import { useParams } from 'react-router-dom';
import { staticsApi } from '../apiCalls/staticsApi';

const StaticFormEdit: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchStatic = async () => {
      setLoading(true);
      const staticItem: any = await staticsApi.getStaticById(id!);
      if (staticItem) {
        console.log('hello static')
        console.log(staticItem)
        form.setFieldsValue({
          name: staticItem.name,
          code: staticItem.code,
        });
      } else {
        notification.error({
          message: 'Error',
          description: 'Static not found.',
          duration: 3,
        });
      }
      setLoading(false);
    };

    fetchStatic();
  }, [id, form]);

  const onFinish = async (values: any) => {
    const staticData = {
      ...values,
    };

    console.log('data sent')
    console.log(staticData)

    const result = await staticsApi.updateStatic(id!, staticData);
    if (result) {
      notification.success({
        message: 'Static Updated',
        description: 'The static has been successfully updated.',
        duration: 3,
      });
    } else {
      notification.error({
        message: 'Error updating',
        description: 'There was an error updating the asset.',
        duration: 3,
      });
    }
  };

  return (
      <div style={{ padding: 24 }}>
        <h2>Edit Static</h2>
        <Form layout="vertical" onFinish={onFinish} form={form}>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="Code" name="code">
                <Input placeholder="Enter the code" disabled />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Name" name="name">
                <Input placeholder="Enter the name" />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              Save Static
            </Button>
          </Form.Item>
        </Form>
      </div>
  );
};

export default StaticFormEdit;
