import React, { useState, useEffect } from 'react';
import { Input } from 'antd';

export const CommaInput = ({
                                                        value,
                                                        onChange,
                                                        allowNegative = false,
                                                        decimalScale = 8,
                                                        ...restProps
                                                      }: any) => {
  const [displayValue, setDisplayValue] = useState('');

  const decimalRegex = allowNegative
      ? new RegExp(`^-?\\d*([.,]\\d{0,${decimalScale}})?$`)
      : new RegExp(`^\\d*([.,]\\d{0,${decimalScale}})?$`);

  useEffect(() => {
    if (value !== undefined && value !== null && !isNaN(value)) {
      setDisplayValue(String(value).replace('.', ','));
    } else {
      setDisplayValue('');
    }
  }, [value]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let typed = e.target.value;

    typed = typed.replace('.', ',');

    if (typed === '' || decimalRegex.test(typed)) {
      setDisplayValue(typed);

      const numericVal = parseFloat(typed.replace(',', '.'));
      onChange?.(isNaN(numericVal) ? undefined : numericVal);
    }
  };

  const handleBlur = () => {
    if (displayValue.endsWith(',')) {
      const trimmed = displayValue.slice(0, -1);
      setDisplayValue(trimmed);

      const numericVal = parseFloat(trimmed.replace(',', '.'));
      onChange?.(isNaN(numericVal) ? undefined : numericVal);
    }
  };

  return (
      <Input
          {...restProps}
          type="text"
          value={displayValue}
          onChange={handleChange}
          onBlur={handleBlur}
      />
  );
};
