import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, notification } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { warningsApi, Warning } from '../apiCalls/warningsApi';

const WarningsList: React.FC = () => {
  const navigate = useNavigate();
  const [dataSource, setDataSource] = useState<Warning[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [deleting, setDeleting] = useState<string | null>(null);

  useEffect(() => {
    fetchWarnings();
  }, []);

  const fetchWarnings = async () => {
    setLoading(true);
    const warnings = await warningsApi.getWarnings();
    setDataSource(warnings);
    setLoading(false);
  };

  const handleDelete = async (id: string) => {
    try {
      setDeleting(id);
      await warningsApi.deleteWarning(id);
      notification.success({
        message: 'Warning Deleted',
        description: 'The warning has been successfully removed.',
        duration: 3,
      });
      fetchWarnings();
    } catch (error: any) {
      notification.error({
        message: 'Error while deleting',
        description: error.message,
        duration: 5,
      });
    } finally {
      setDeleting(null);
    }
  };

  const showDeleteConfirm = (record: Warning) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this warning?',
      content:
          'If this warning is in use in any product or ingredient, it cannot be removed.',
      okText: 'Yes, Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        handleDelete(record.id);
      },
    });
  };

  const columns = [
    {
      title: 'Edit',
      key: 'edit',
      fixed: 'left' as const,
      width: 70,
      render: (_: any, record: Warning) => (
          <Button
              onClick={() => {
                navigate(`/edit-warning/${record.id}`);
              }}
              icon={<EditOutlined />}
          />
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Delete',
      key: 'delete',
      fixed: 'right' as const,
      width: 70,
      render: (_: any, record: Warning) => (
          <Button
              danger
              icon={<DeleteOutlined />}
              onClick={() => showDeleteConfirm(record)}
              loading={deleting === record.id}
          />
      ),
    },
  ];

  return (
      <div style={{ padding: 24 }}>
        <h2>List of Warnings</h2>
        <Button
            type="primary"
            onClick={() => navigate('/add-warning')}
            style={{ marginBottom: 16 }}
        >
          Add New Warning
        </Button>
        <Table
            dataSource={dataSource}
            columns={columns}
            rowKey="id"
            loading={loading}
            scroll={{ x: 'max-content' }}
        />
      </div>
  );
};

export default WarningsList;
