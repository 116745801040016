import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, notification } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { activesApi, Active } from '../apiCalls/activesApi';

const ActivesList: React.FC = () => {
  const navigate = useNavigate();
  const [dataSource, setDataSource] = useState<Active[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [deleting, setDeleting] = useState<string | null>(null);

  useEffect(() => {
    fetchActives();
  }, []);

  const fetchActives = async () => {
    setLoading(true);
    const actives = await activesApi.getActives();
    setDataSource(actives);
    setLoading(false);
  };

  const handleDelete = async (id: string) => {
    try {
      setDeleting(id);
      await activesApi.deleteActive(id);
      notification.success({
        message: 'Active Deleted',
        description: 'The active has been successfully removed.',
        duration: 3,
      });
      fetchActives();
    } catch (error: any) {
      notification.error({
        message: 'Error while deleting',
        description: error.message,
        duration: 5,
      });
    } finally {
      setDeleting(null);
    }
  };

  const showDeleteConfirm = (record: Active) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this active?',
      content:
          'If this active is in use in any product or ingredient, it cannot be removed.',
      okText: 'Yes, Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        handleDelete(record.id);
      },
    });
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'VRN',
      dataIndex: 'vrn',
      key: 'vrn',
      render: (value: number | null) => (value !== null ? value : 'N/A'),
    },
    {
      title: 'VRN Unit',
      dataIndex: 'vrnUnit',
      key: 'vrnUnit',
    },
    {
      title: 'I.U. Claim',
      dataIndex: 'iuClaim',
      key: 'iuClaim',
      render: (value: number | null) => (value !== null ? value : 'N/A'),
    },
    {
      title: 'UL',
      dataIndex: 'ul',
      key: 'ul',
      render: (value: number | null) => (value !== null ? value : 'N/A'),
    },
    {
      title: 'UL Unit',
      dataIndex: 'ulUnit',
      key: 'ulUnit',
    },
    {
      title: '',
      key: 'edit',
      fixed: 'left' as const,
      width: 40,
      render: (_: any, record: Active) => (
          <Button
              onClick={() => {
                navigate(`/edit-active/${record.id}`);
              }}
              icon={<EditOutlined />}
          />
      ),
    },
    {
      title: '',
      key: 'delete',
      fixed: 'right' as const,
      width: 40,
      render: (_: any, record: Active) => (
          <Button
              danger
              icon={<DeleteOutlined />}
              onClick={() => showDeleteConfirm(record)}
              loading={deleting === record.id}
          />
      ),
    },
  ];

  return (
      <div style={{ padding: 24 }}>
        <h2>List of Actives</h2>
        <Button
            type="primary"
            onClick={() => navigate('/add-active')}
            style={{ marginBottom: 16 }}
        >
          Add New Active
        </Button>
        <Table
            dataSource={dataSource}
            columns={columns}
            rowKey="id"
            loading={loading}
            scroll={{ x: 'max-content' }}
        />
      </div>
  );
};

export default ActivesList;
