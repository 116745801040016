import { apiClient } from './apiClient';

export interface Claim {
  id: string;
  name: string;
}

export const claimsApi = {
  getClaims: async (): Promise<Claim[]> => {
    try {
      const response = await apiClient.get<Claim[]>('/lists/claims');
      return response.data;
    } catch (error) {
      console.error('Error fetching claims:', error);
      return [];
    }
  },
  getClaimById: async (id: string): Promise<Claim | null> => {
    try {
      const response = await apiClient.get<Claim>(`/claims/${id}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching claim by ID:', error);
      return null;
    }
  },
  createClaim: async (claimData: Partial<Claim>): Promise<Claim | null> => {
    try {
      const response = await apiClient.post<Claim>('/claims', claimData);
      return response.data;
    } catch (error) {
      console.error('Error creating claim:', error);
      return null;
    }
  },
  updateClaim: async (id: string, claimData: Partial<Claim>): Promise<Claim | null> => {
    try {
      const response = await apiClient.post<Claim>(`/claims/${id}/update`, claimData);
      return response.data;
    } catch (error) {
      console.error('Error updating claim:', error);
      return null;
    }
  },
  deleteClaim: async (id: string): Promise<boolean> => {
    try {
      await apiClient.delete(`/claims/${id}/delete`);
      return true;
    } catch (error: any) {
      if (error.response && error.response.status === 403) {
        console.error('Cannot delete claim, it is used in a product/ingredient.');
      } else {
        console.error('Error deleting claim:', error);
      }
      return false;
    }
  },
};
