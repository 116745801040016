import React, {useState} from 'react';
import {Menu, Dropdown, Button, Layout} from 'antd';
import {UserOutlined} from '@ant-design/icons';
import {useNavigate} from 'react-router-dom';
import {LocalStorage} from '../LocalStorage';
const {Header} = Layout;

const NavBar = () => {
  const [current, setCurrent] = useState('productos');
  const navigate = useNavigate();

  const onClick = (e: any) => {
    setCurrent(e.key);
    navigate(e.key, {replace: true});
  };

  const logout = () => {
    LocalStorage.clearAll();
    navigate('/login', {replace: true});
  };

  const goToProfile = () => {
    navigate('/profile');
  };

  const goToHome = () => {
    navigate('/view-all-ingredients');
  };

  return (
    <Header style={{position: 'fixed', zIndex: 999, width: '100%', backgroundColor: '#87CEEB'}}>
      <div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <div style={{flex: 1, flexDirection: 'row', display: 'flex'}}>
            <img
              onClick={goToHome}
              src={require('../assets/umbrella-logo.png')}
              alt="Logo"
              style={{height: 45, alignSelf: 'center', marginRight: 20, cursor: 'pointer'}}
            />
            <Menu
              onClick={onClick}
              selectedKeys={[current]}
              mode="horizontal"
              style={{backgroundColor: '#87CEEB', flex: 1}}
              items={[
                {
                  label: 'Ingredients',
                  key: 'productos',
                  children: [
                    {
                      label: 'Add new ingredient',
                      key: '/add-ingredient',
                    },
                    {
                      label: 'Ingredient list',
                      key: '/view-all-ingredients',
                    },
                  ],
                },
                {
                  label: 'Actives',
                  key: 'actives',
                  children: [
                    {
                      label: 'Add new active',
                      key: '/add-active',
                    },
                    {
                      label: 'Active list',
                      key: '/view-all-actives',
                    },
                  ],
                },
                {
                  label: 'Warnings',
                  key: 'warnings',
                  children: [
                    {
                      label: 'Add new warning',
                      key: '/add-warning',
                    },
                    {
                      label: 'Warnings list',
                      key: '/view-all-warnings',
                    },
                  ],
                },
                {
                  label: 'Claims',
                  key: 'claims',
                  children: [
                    {
                      label: 'Add new claim',
                      key: '/add-claim',
                    },
                    {
                      label: 'Claims list',
                      key: '/view-all-claims',
                    },
                  ],
                },
                {
                  label: 'Statics',
                  key: 'statics',
                  children: [
                    {
                      label: 'Statics list',
                      key: '/view-all-statics',
                    },
                  ],
                },
                // {
                //   label: 'Brands',
                //   key: '/brands',
                // },
                // {
                //   label: 'Suppliers',
                //   key: '/providers',
                // },
                {
                  label: 'Formulation',
                  key: 'formulation',
                  children: [
                    {
                      label: 'Add new formulation',
                      key: '/add-formulation',
                    },
                    {
                      label: 'Formulation list',
                      key: '/view-all-formulation',
                    },
                  ],
                },
              ]}
            />
          </div>

          <Dropdown
            overlay={
              <Menu style={{zIndex: 99999}}>
                {/* eslint-disable-next-line @typescript-eslint/no-empty-function */}
                <Menu.Item key="profile" onClick={goToProfile}>
                  Profile
                </Menu.Item>
                <Menu.Item key="logout" onClick={logout}>
                  Logout
                </Menu.Item>
              </Menu>
            }
            trigger={['click']}
          >
            <Button style={{marginLeft: 8}}>
              <UserOutlined />
            </Button>
          </Dropdown>
        </div>
      </div>
    </Header>
  );
};

export default NavBar;
