import React from 'react';
import { Form, Input, Button, notification, Row, Col } from 'antd';
import { claimsApi } from '../apiCalls/claimsApi';

const AddClaimForm: React.FC = () => {

  const onFinish = async (values: any) => {
    const claimData = {
      ...values,
    };

    const result = await claimsApi.createClaim(claimData);
    if (result) {
      notification.success({
        message: 'Claim Created',
        description: 'The claim has been created successfully.',
        duration: 3,
      });
    } else {
      notification.error({
        message: 'Error al Crear',
        description: 'Hubo un error al crear el claim.',
        duration: 3,
      });
    }
  };

  return (
      <div style={{ padding: 24 }}>
        <h2>Add new claim</h2>
        <Form layout="vertical" onFinish={onFinish}>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="Name" name="name" rules={[{ required: true }]}>
                <Input placeholder="Enter the name" />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Add Claim
            </Button>
          </Form.Item>
        </Form>
      </div>
  );
};

export default AddClaimForm;
