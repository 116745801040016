import { apiClient } from './apiClient';

export interface Static {
  id: string;
  name: string;
}

export const staticsApi = {
  getStatics: async (): Promise<Static[]> => {
    try {
      const response = await apiClient.get<Static[]>('/lists/statics');
      return response.data;
    } catch (error) {
      console.error('Error fetching statics:', error);
      return [];
    }
  },
  getStaticById: async (id: string): Promise<Static | null> => {
    try {
      const response = await apiClient.get<Static>(`/statics/${id}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching static by ID:', error);
      return null;
    }
  },
  createStatic: async (staticData: Partial<Static>): Promise<Static | null> => {
    try {
      const response = await apiClient.post<Static>('/statics', staticData);
      return response.data;
    } catch (error) {
      console.error('Error creating static:', error);
      return null;
    }
  },
  updateStatic: async (id: string, staticData: Partial<Static>): Promise<Static | null> => {
    try {
      const response = await apiClient.post<Static>(`/statics/${id}/update`, staticData);
      return response.data;
    } catch (error) {
      console.error('Error updating static:', error);
      return null;
    }
  },
  deleteStatic: async (id: string): Promise<boolean> => {
    try {
      await apiClient.delete(`/statics/${id}/delete`);
      return true;
    } catch (error: any) {
      if (error.response && error.response.status === 403) {
        console.error('Cannot delete static, it is used in a product/ingredient.');
      } else {
        console.error('Error deleting static:', error);
      }
      return false;
    }
  },
};
